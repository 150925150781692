<template>
    <div v-if="active"  :class="'m-alert m-alert--outline alert alert-' + type +' alert-dismissible'" role="alert">
        <button type="button" class="close" v-on:click="$emit('close')"></button>
        <span>{{msg}}</span>
    </div>
</template>

<script>

export default {
    name:'ErrorForm',
    props:{
        active:{
            default:false,
            type:Boolean
        },
        type:{
            default:'',
            type:String
        },
        msg:{
            default:'',
            type:String
        },
    },
}

</script>