<template>
<!--Begin::Portlet-->
<div class="m-portlet m-portlet--full-height m-portlet--mobile border-primary border-top">
  <div class="m-portlet__head">
    
    <div class="m-portlet__head-caption">
      <div class="m-portlet__head-title">
        <div class="nav-link">
          <h3 class="m-portlet__head-text">
            Educación Financiera
          </h3>
        </div>
      </div>
    </div>
  </div>
  <div class="m-portlet__body">
    <div class="m-widget4">
      <div class="m-widget4__item"
        v-for="post in posts"
        :key="post.id"
         v-on:click="redirect(post.relative_path)">
        <div class="m-widget4__img">
          <img :src="post.img_src" :alt="post.title" />
        </div>
        
        <div class="m-widget4__info">
          <span class="m-widget4__text">
            {{post.title}}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<!--End::Portlet-->
</template>


<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "PostFinanceEducationWidget",
   props:{
     posts:{
            type: Array,
            default: () => ([])
        },
   },
  	methods:{
      redirect: function (relative_path) {
        window.open('http://qnt.com.co/'+relative_path,'_blank');
      }
  }
};
</script>

<style scoped>
.m-widget4__item{
  cursor: pointer;
}

img{
    width: 120px;
  }
</style>
