<template>
  <div class="text-center">
    <v-dialog
      :value="dialog"
      @input="$emit('update:dialog', $event)"
      width="320"
    >
      <v-card class="text-center">
        <v-toolbar flat>
          <v-btn
            icon
            dark
            color="secondary"
            right
            absolute
            @click="$emit('update:dialog', false)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          ¡Felicitaciones! Tu solicitud de tarjeta de crédito VISA QNT se ha almacenado con éxito, en un plazo máximo de 48 horas hábiles, nos estaremos comunicando contigo, para terminar el proceso.
        </v-card-text>

        <v-card-text>
          Confírmanos el número al cual nos debemos comunicar
        </v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container>
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-text-field
                  v-model="mobile"
                  shaped
                  autofocus
                  outlined
                  type="tel"
                  maxlength="10"
                  class="centered-input"
                  :rules="[
                            v => !!v || 'Número de celular es requerido',
                            v => (v && v.length == 10) || 'Número de celular debe tener 10 digitos',
                            val => val && /^3[0-5]{1}\d{8}$/.test(val) || 'Ingrese un número de celular valido',
                        ]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="justify-center">
              <v-col
                cols="12"
                md="8"
                class="pt-0"
              >
                <v-btn
                  color="secondary"
                  @click.prevent="submit"
                  block
                >
                  CONFIRMAR
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: "CreditCardModal",
  data () {
    return {
      valid: true,
      mobile: '',
    }
  },
  props: {
    dialog: {
      default: false,
    },
    product: {
      type: Object,
      default: () => ({})
    },
    defaultMobile: {
      type: [String, Number],
      default: '',
    }

  },
  created () {
    this.mobile = this.defaultMobile;
  },
  methods: {
    submit () {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.$emit("update-mobile", { celular: this.mobile, product_id: this.product.external_id })
        this.$emit('update:dialog', false);
      }

    }
  },
  watch: {
    defaultMobile (newVal) {
      if (!!newVal) {
        this.mobile = newVal;
      }
    },
  }
}
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>