<template>
  <!--Begin::Portlet-->
  
  <div class="m-portlet m-portlet--full-height m-portlet--mobile border-warning border-top">
    <div class="m-portlet__head">
      <div class="m-portlet__head-caption">
        <div class="m-portlet__head-title">
          <router-link to="/contratos" class="nav-link">
            <h3 class="m-portlet__head-text">
              Mis Contratos
            </h3>
          </router-link>
        </div>
      </div>
    </div>
    <div class="m-portlet__body">
      <div class="m-widget4">
        <div
          class="m-widget4__item"
          v-for="document in documents"
          :key="document.id">
          <div class="m-widget4__img m-widget4__img--icon">
            <img src="../../assets/theme/app/media/img/files/pdf.svg" alt="">
          </div>
          <div class="m-widget4__info">
            <span class="m-widget4__text">
              {{ document.tipo_documento }}
            </span>
          </div>
          <div
            class="m-widget4__ext"
            v-on:click="genererate_docs(document.id, document.tipo_documento + '.pdf')">
            <a class="m-widget4__icon">
              <i class="la la-download"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End::Portlet-->




</template>


<script>
import { mapState } from "vuex";
import documentService from '@/services/document.service'

export default {
  name: "DocumentWidget",
  computed: {
    ...mapState({
      documents: state => state.documents.legalDocuments,
    }),
  },
  methods: {
    genererate_docs: function (id, doc) {
      documentService.getPdfLegalDocument(id, doc);
    }
  },
  mounted() {
    if (!this.documents.length || this.$route.params.reload) {
      this.$store.dispatch("documents/getLegalDocument")
    }
  },
};
</script>
