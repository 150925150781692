<template>
  <div class="card-info">
    <v-card elevation="1">
      <v-card-title>
        Tarjeta de Rebancarización
      </v-card-title>

      <v-card-text>
        <div class="row">
          <div class="col-sm-3 d-flex justify-content-center">
            <v-icon large>
              fa-regular fa-credit-card
            </v-icon>
          </div>
          <div class="col-sm-9 section-info p-2">
            Consulte la información de su tarjeta de rebancarización
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-content-center">
        <v-btn
          class="pa-sm-2 pa-sm-2"
          color="info"
          outlined
          small
          rounded
          @click="navigateToTD"
        >
          <strong>Ir a ver mi tarjeta</strong>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>

</template>

<script>
export default {
  name: 'TarjetaInfoWidget',
  methods: {
    navigateToTD() {
      this.$router.push({name: 'user-DigitalCreditCards',});
    }
  }
}
</script>

<style scoped>

.card-info {
  border-top: 5px solid #36a3f7;
}
.section-info {
  font-size: 1rem;
  font-weight: 400;
}
</style>