<template>
  <div class="m-portlet m-portlet--full-height m-portlet--mobile porlet-blue-border porlet-products">
    <div class="m-portlet__head">
      <div class="m-portlet__head-caption">
        <div class="m-portlet__head-title">
          <h3 class="m-portlet__head-text">
            EL EMPLEO VIP
          </h3>
        </div>
      </div>
    </div>

    <div class="m-portlet__body pt-2">
      <div class="m-widget3">
        <div class="m-widget3__item">
          <div class="m-widget3__header">
            <div class="m-widget3__info">
              <div
                class="m-widget3__username"
                v-if="haveDelinquentAccounts"
              >
                <p class="m-widget3__text mt-2">
                  Recuerda que para acceder a este beneficio debes estar al día. Cancela la(s) cuota(s) pendientes
                </p>
              </div>
              <div
                class="m-widget3__username"
                v-else-if="isActive"
              >
                <p class="m-widget3__text mt-2">
                  Felicitaciones! Tienes el Beneficio:<br />
                  <span class="link">EL EMPLEP VIP</span>.<br />
                  Activalo diligenciando los siguientes datos:
                </p>
              </div>
              <div
                class="m-widget3__username"
                v-else-if="wasRequested"
              >
                <p class="m-widget3__text text-center mt-2">
                  Su solicitud ha sido enviada
                  <br />
                  <br />
                  En 48 horas, su suscripción será activada.
                </p>
              </div>
            </div>
          </div>

          <div
            class="m-widget3__body"
            v-if="isActive"
          >
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="submit"
            >
              <v-row>
                <v-col cols="12">
                  <v-dialog
                    v-model="dialogBirthDate"
                    ref="dialogBirthDate"
                    :return-value.sync="dataForm.dialogBirthDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        light
                        dense
                        color="secondary"
                        label="Fecha de nacimiento"
                        v-model="dataForm.dialogBirthDate"
                        ref="birth_date"
                        append-icon="mdi-calendar"
                        :rules="rules.dateRules"
                        required
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="secondary"
                      v-model="dataForm.birth_date"
                      locale="es-co"
                      @input="$refs.dialogBirthDate.save(dataForm.birth_date)"
                      :max="current_day"
                      min="1950-01-01"
                      :active-picker.sync="activePicker"
                    >
                    </v-date-picker>
                  </v-dialog>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="dataForm.email"
                    light
                    dense
                    :rules="rules.emailRules"
                    label="Correo electrónico registrado en EL EMPLEO.COM"
                    hide-details="auto"
                    color="secondary"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    color="secondary"
                    block
                    type="submit"
                    :disabled="loading"
                    :loading="loading"
                  >
                    ACTIVAR
                  </v-btn>
                </v-col>
              </v-row>

            </v-form>

            <p class="pt-4 mb-2 text-center">
              En 48 horas, su suscripción será activada.
            </p>

            <ErrorFormAlert :errors="errors" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ErrorFormAlert from "@/components/ui/ErrorFormAlert"

export default {
  name: "UserElEmpleoWidget",
  components: {
    ErrorFormAlert
  },
  data() {
    return {
      activePicker: null,
      loading: false,
      wasRequested: false,
      dataForm: {
      },
      dialogBirthDate: false,
      valid: true,
      errors: null,
      rules: {
        emailRules: [
          v => !!v || 'Correo es requerido',
          v => /.+@.+\..+/.test(v) || 'Correo debe ser valido',
        ],
        dateRules: [
          v => !!v || 'Fecha es requerida',
          v => /^[1-2]{1}\d{3}-[0-1][0-9]-[0-3][0-9]$/.test(v) || 'Fecha invalida',
        ],
      },
    }
  },
  props: {
    product: {
      type: Object,
      default: () => ({})
    },
    haveDelinquentAccounts: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
    this.dataForm = { ...this.product }
  },
  methods: {
    ...mapActions('products', ['activeElEmpleo']),
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          await this.activeElEmpleo({ ...this.dataForm });
          this.wasRequested = true;
        } catch (error) {
          if (error.response) {
            this.errors = error.response.data
          } else {
            throw error
          }
        }
        this.loading = false;
      }
      return 1
    }
  },
  computed: {
    isActive() {
      const state = this.product.state;
      if (!state || state != "Preaprobado" || this.haveDelinquentAccounts) {
        return false;
      } else {
        return true;
      }
    },
    current_day() {
      let date = new Date();
      date.setHours(0)
      return date.toISOString().substr(0, 10)
    },
  },
  watch: {
    dialogBirthDate(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    product(val) {
      if (Object.keys(val).length > 0) {
        this.dataForm = { ...val }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.m-widget3 {
  .m-widget3__item {
    .m-widget3__header {
      display: flex;
      .m-widget3__info {
        align-self: center;
        .m-widget3__username {
          .link {
            color: #22b9ff;
            font-weight: bold;
          }
          a {
            color: #575962;
            text-decoration: underline;
          }
        }
      }
    }
    .m-widget3__body {
      .m-widget3__text {
        color: #414141;
        font-size: 1.5rem;
      }
    }
    .m-widget3__user-img {
      align-self: center;
      .m-widget3__img {
        width: 4.2rem;
        border-radius: 0px;
      }
    }
  }
}

.m-dropdown[m-dropdown-toggle="hover"] {
  padding: 8px 5px;
  a {
    text-decoration: none;
  }
}
</style>