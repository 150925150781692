<template>
<div class="m-widget4">
    <div class="m-widget4__item" data-toggle="modal" data-target="#m_modal_1_2" 
     v-for="alert in alertList" 
    :key="alert.id"
    @click="$emit('switch-alert', alert.id)"
    >
        <div class="m-widget4__ext">
            <span class="m-widget4__icon m--font-brand">
                <i :class="'flaticon-email-black-circular-button m--font-' +( alert.read?'success':alert.type)"></i>
            </span>
        </div>
        <div class="m-widget4__info">
            <span class="m-widget4__text">
                {{alert.subject}}
            </span>
        </div>
        <div class="m-widget4__ext">
            <span :class="'m-widget4 m--font-'+ alert.type">
               {{alert.create_at | moment("from")}}
            </span>
        </div>
    </div>
    
</div>
</template>

<script>

import PaymentButton from "@/components/ui/PaymentButton.vue";

export default {
    name: "AlertList",
    props:{
        alertList:{
            default:[]
        }
    }
}
</script>