<template>
  <!--begin::Modal-->
  <div
    class="modal fade"
    id="m_modal_4"
    tabindex="-1"
    role="dialog"
    aria-labelledby="passwordModal"
    aria-hidden="true"
  >
    <div
      class="modal-dialog"
      role="document"
    >

      <form @submit.prevent="changePassword">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              id="passwordModal"
            >Para conocer el detalle de tu acuerdo con QNT por favor ingresa una clave, Esta te servirá para consultar cuantas veces quieras</h5>
          </div>
          <div class="modal-body">
            <ErrorForm
              :msg="msg"
              :type="'danger'"
              :active="activeError"
              v-on:close="close"
            />
            <div class="form-group m-form__group row">
              <label
                for="example-text-input"
                class="col-3 col-form-label"
              >Contraseña</label>
              <div class="col-8">
                <input
                  class="form-control m-input"
                  type="password"
                  name="new_password1"
                  required
                  v-model="new_password1"
                >
              </div>
            </div>
            <div class="form-group m-form__group row">
              <label
                for="example-text-input"
                class="col-3 col-form-label"
              >Repetir Contraseña</label>
              <div class="col-8">
                <input
                  class="form-control m-input"
                  type="password"
                  name="new_password2"
                  required
                  v-model="new_password2"
                >
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-primary"
            >Confirmar</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <!--end::Modal-->

</template>

<script>
import { mapGetters } from "vuex";
import ErrorForm from '@/components/ui/ErrorForm.vue';

export default {
  name: "PasswordModal",

  components: {
    ErrorForm,
  },

  data () {
    return {
      new_password1: '',
      new_password2: '',
    }
  },
  computed: {
    ...mapGetters({
      msg: "users/firstError",
      activeError: "users/hasErrors",
    }),
  },
  methods: {
    changePassword () {
      let new_password1 = this.new_password1;
      let new_password2 = this.new_password2;
      this.$store.dispatch('users/changePassword', { new_password1, new_password2 });
    },
    close () {
      this.$store.commit('users/setError', {});
    }
  }
}
</script>