<template>        
  <div class="m-portlet m-portlet--mobile border-success border-top">
    <div class="m-portlet__head">
      <div class="m-portlet__head-caption">
        <div class="m-portlet__head-title">
          <router-link to="/pagos" class="nav-link">
            <h3 class="m-portlet__head-text">QNT - {{products.entities}}</h3>
            </router-link>
        </div>
      </div>
    </div>
    <div class="m-portlet__body py-1">
      <div class="m-widget14 px-0 py-1">
          <div class="row  align-items-center" >
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div>
                <h4 class="text-uppercase font-light"></h4>
              </div>
              <div class="row align-items-center porlet-wrapper-bordered my-3 mx-0 py-3" v-if="fees.length">
                <div class="col-12 col-sm-12 col-xl-6 text-center">
                  <span class="xsmall-text">Próxima cuota a pagar</span>
                  <h3 class="mb-0">{{next_payment.saldo | currency}}</h3>
                </div>
                <div class="col-12 col-sm-12 col-xl-6 text-center">
                  <span class="xsmall-text">Fecha de pago</span>
                  <h3 class="mb-0">{{next_payment.fecha}}</h3>
                </div>
              </div>
              
              <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center my-4">
                <h4 class="text-uppercase font-light">
                  <router-link to="pagos" style="color: #575962;">
                    Hacemos más fácil el pago de sus productos
                  </router-link>
                </h4>
              </div>
              <div class="row align-items-center my-3 py-2 mx-0 justify-content-md-center">
                <div class="col-12 col-sm-12 col-xl-6 text-center my-2"
                  v-for="channel in channels"
                  v-on:click="switchChannel(channel)" >

                  <a v-if="channel.url" class="btn btn-outline-info btn-block " target="_blank"
                   :href="channel.url"><b>Descubra como pagar</b>
                   </a>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>

    <div class="modal fade" id="m_modal_document" tabindex="-1" role="dialog" aria-labelledby="documentModal" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
              <div @click.prevent="downloadItem()">
                <span class="modal-title mr-3">Descargar</span>
                <button type="button" class="m-btn btn btn-secondary">
                  <i class="la la-floppy-o"></i>
                </button>
              </div>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <div class="m-scrollable" data-scrollbar-shown="true" data-scrollable="true" data-height="200">
                    <div v-html="document"></div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import PaymentButton from "@/components/ui/PaymentButton.vue";
import documentService from '@/services/document.service'

export default {
  name: "PaymentWidget",
  components:{
    PaymentButton,
  },
  computed: {
    ...mapState({
      payments: state => state.payments.all,
      document: state => state.payments.document,
      channels: state => state.payments.instructiveUrlList,
      currentChannel: state => state.payments.currentChannel,
      products: state => state.payments.products,
    }),
    ...mapGetters({
      havePayments: "payments/havePayments",
      fees: "fees/pendingFees",
      next_payment: "fees/nextFee",
    })
  },
  created(){
    if (!this.channels.length){
      this.$store.dispatch("payments/getProductsAndPaymentChannels");
    }
    
  },
  methods: {
      ...mapActions('payments', [
      'switchChannel'
    ]),

    downloadItem () {
      if(!this.currentChannel.url){
        documentService.download(this.currentChannel.id, `${this.currentChannel.name}_${this.currentChannel.channel}.pdf`);
      }
    },
  }
};
</script>
