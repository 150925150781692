<template>
<!--Begin::Portlet-->
<div class="m-portlet m-portlet--full-height m-portlet--mobile border-danger border-top">
  <div class="m-portlet__head">
    <div class="m-portlet__head-caption">
      <div class="m-portlet__head-title">
      <router-link to="/notificaciones" class="nav-link">
        <h3 class="m-portlet__head-text">
          Mis Notificaciones
        </h3>
      </router-link>
      </div>
    </div>
    <div class="m-portlet__head-tools">
      <ul class="nav nav-pills nav-pills--brand m-nav-pills--align-right m-nav-pills--btn-pill m-nav-pills--btn-sm" role="tablist">
        <li class="nav-item m-tabs__item">
          <a class="nav-link m-tabs__link active" data-toggle="tab" href="#m_widget2_tab1_content" role="tab">
            Sin leer
          </a>
        </li>
        <li class="nav-item m-tabs__item">
          <a class="nav-link m-tabs__link" data-toggle="tab" href="#m_widget2_tab2_content" role="tab">
            Hoy
          </a>
        </li>
      </ul>
    </div>
  </div>
  
  <div class="m-portlet__body">
    <div class="tab-content">
      <div class="tab-pane active" id="m_widget2_tab1_content">

        <!--Begin::Timeline 3 -->
        <AlertList 
        :alert-list="alerts"
        @switch-alert="switchAlert"
        />
        <!--End::Timeline 3 -->
      </div>
      <div class="tab-pane" id="m_widget2_tab2_content">
        <!--Begin::Timeline 3 -->
        <AlertList 
        :alert-list="alertsToday"
        @switch-alert="switchAlert"
        />
        <!--End::Timeline 3 -->
      </div>
    </div>
  </div>
</div>
<!--End::Portlet-->
</template>


<script>
import { mapState, mapGetters, mapActions } from "vuex";
import AlertList from "./AlertList.vue";

export default {
  name: "AlertWidget",
  components:{
    AlertList
  },
  computed: {
    ...mapGetters({
      alertsToday: "alerts/alertsToday",
      alerts:"alerts/alertsNotRead"
    }),
  },
  methods: mapActions('alerts', [
    'switchAlert'
  ]),
};
</script>
