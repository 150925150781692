<template>
  <div class="m-portlet m-portlet--full-height m-portlet--mobile porlet-blue-border porlet-products">
    <div class="m-portlet__head">
      <div class="m-portlet__head-caption">
        <div class="m-portlet__head-title">
          <h3 class="m-portlet__head-text">
            Desvare
          </h3>
          <div
            class="m-portlet__nav-item m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-left m-dropdown--align-push"
            m-dropdown-toggle="hover"
            aria-expanded="true"
          >
            <a
              href="#"
              class="m-portlet__nav-link btn btn-secondary m-btn--icon m-btn--icon-only m-btn--pill m-dropdown__toggle"
            >
              <i class="la la-ellipsis-v"></i>
            </a>
            <div class="m-dropdown__wrapper">
              <span
                class="m-dropdown__arrow m-dropdown__arrow--left m-dropdown__arrow--adjust"
                style="right: auto; left: 10.5px;"
              ></span>
              <div class="m-dropdown__inner">
                <div class="m-dropdown__body">
                  <div class="m-dropdown__content">
                    <p>El desvare es un beneficio de Qnt, para premiar el buen comportamiento de nuestros clientes; En donde puedes pedir un préstamo rápido, para esos gastos imprevistos que se puedan presentar</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="m-portlet__head-tools"
        v-if="isActive"
      >
        <ul class="m-portlet__nav">
          <li class="m-portlet__nav-item">
            <router-link
              to="/desvare"
              class="m-portlet__nav-link btn btn-info m-btn m-btn--pill m-btn--air"
            >
              {{wasRequested?'Ver detalles':'Solicitar'}}
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="m-portlet__body pt-2">
      <div class="m-widget3">
        <div class="m-widget3__item">
          <div class="m-widget3__header">
            <div class="m-widget3__user-img">
              <img
                class="m-widget3__img"
                src="../../../assets/img/desvare.png"
                alt=""
              >
            </div>
            <div class="m-widget3__info">
              <span
                class="m-widget3__username"
                v-html="description"
              >
              </span>
            </div>
          </div>
          <div
            class="m-widget3__body"
            v-if="isActive"
          >
            <p
              class="m-widget3__text text-center mt-2"
              v-if="wasRequested"
            >
              <strong>Fecha Límite de pago:</strong><br><strong class="text-green">{{product.payment_date}}</strong>
            </p>
            <p
              class="m-widget3__text text-center mt-2"
              v-else-if="product.state=='ACTIVO'"
            >
              <strong>Monto Aprobado:</strong><br><strong class="text-green">{{product.total_amount | currency}} COP</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "UserDesvareWidget",
  props: {
    product: {
      type: Object,
      default: () => ({})
    },
    haveDelinquentAccounts: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: "https://qnt.com.co/blog/desvare/"
    },
  },
  computed: {
    description () {
      const state = this.product.state;
      let description = "";
      if (!state || state == "ANULADO") {
        description = `Aún no tenemos una oferta de <a href="${this.url}" target="_blank">desvare</a> para ti, Continua cumpliendo el pago de tus cuotas oportunas y pronto se activara esta opción`;
      }
      else if (this.haveDelinquentAccounts) {
        description = `Para solicitar tu <a href="${this.url}" target="_blank">desvare</a>, debes estar al día en las cuotas de tu acuerdo de pago`;
      }
      else if (state == "ACTIVO") {
        description = `Tienes disponible el <a href="${this.url}" target="_blank">desvare</a>, para que lo solicites cuando sea necesario. Puedes retirarlo en un punto Efecty`;
      }
      else if (state == "UTILIZADO") {
        description = `Tienes un desvare activo, retirado el día ${this.product.delivery_at}`;
      }
      else if (state == "PENDIENTE RETIRO") {
        description = "Tienes un desvare activo";
      }
      return description
    },
    isActive () {
      const state = this.product.state;
      if (!state || state == "ANULADO" || this.haveDelinquentAccounts) {
        return false;
      } else {
        return true;
      }
    },
    wasRequested () {
      return this.product.state == "UTILIZADO" || this.product.state == "PENDIENTE RETIRO"
    },
  }
};
</script>

<style lang="scss" scoped>
.m-widget3 {
  .m-widget3__item {
    .m-widget3__header {
      display: flex;
      .m-widget3__info {
        align-self: center;
        .m-widget3__username {
          a {
            color: #575962;
            text-decoration: underline;
          }
        }
      }
    }
    .m-widget3__body {
      .m-widget3__text {
        color: #414141;
        font-size: 1.5rem;
      }
    }
    .m-widget3__user-img {
      align-self: center;
      .m-widget3__img {
        width: 4.2rem;
        border-radius: 0px;
      }
    }
  }
}

.m-dropdown[m-dropdown-toggle="hover"] {
  padding: 8px 5px;
  a {
    text-decoration: none;
  }
}
</style>