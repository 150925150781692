<template>
  <div class="m-grid__item m-grid__item--fluid m-wrapper">

    <div class="fixed-top qnt-alert">
      <div class="row justify-content-end">
        <div class="col-lg-5 col-md-7 col-sm-9">
          <ShortAlert v-for="p_alert in paymentAlerts" :content="p_alert.content" :type="p_alert.type"
            :key="p_alert.id" />
        </div>
      </div>
    </div>
    <!-- <div class="row plan">
      <div class="col-12 text-center py-0">
        <form>
          <div class="m-portlet mb-2">
            <div class="m-portlet__head">
              <div class="m-portlet__head-caption mx-auto">
                <div class="m-portlet__head-title">
                  <h3 class="m-portlet__head-text">
                    <span class="pr-2">Plan</span>
                    <v2-select
                      :clearable="false"
                      style="width: 200px;"
                      :options="plans"
                      label="name"
                      :value="selectedPlan"
                      @input="changePlan"
                    />
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div> -->
    <!-- Begin:: body page -->
    <v-container>
      <div class="row">
        <!-- Begin:: primerpago table  -->
        <div class="col-xl-6">
          <PaymentWidget />
        </div>
        <!-- End:: primerpago table  -->
        <!-- Begin:: Credit card  -->
        <div class="col-xl-6" v-if="!!creditCard || !anyCreditCard">
          <CreditCardWidget :product="creditCard" :debt_fees="debtFeeAmount"
            :haveDelinquentAccounts="(haveDelinquentAccounts || hasPendingFees)" />
        </div>
        <!-- End:: Credit card  -->

        <!-- seccion para navegar hacia trajeta digital cuando ya tiene una tarjeat activa -->
        <div class="col-xl-6" v-if="acceptedCreditCard || canceledWithArreasBalanceRP">
          <TarjetaInfoWidget></TarjetaInfoWidget>
          <div class="d-flex h-75 my-4 banner">
            <div class="container h-75 rounded-lg">
              <img src="../../../public/assets/theme/app/media/img/bannersTDR/Banner 1.png"
                alt="banner con señora mostrando tarjeta virtual y un mensaje de la importancia de un buen historial crediticio"
                class="img-fluid rounded-lg">
            </div>
          </div>
        </div>

        <!-- Begin:: EL Empleo  -->
        <div class="col-xl-6" v-if="Object.keys(elempleo).length !== 0">
          <ElEmpleoWidget :product="elempleo" :haveDelinquentAccounts="(haveDelinquentAccounts || hasPendingFees)" />
        </div>
        <!-- End:: EL Empleo   -->
        <!-- Begin:: Desvare  -->
        <!-- <div class="col-xl-6">
          <DesvareWidget
            :product="desvare"
            :haveDelinquentAccounts="(haveDelinquentAccounts || hasPendingFees)"
          />
        </div> -->
        <!-- End:: Desvare  -->
        <!-- Begin:: Credit score table  -->
        <!-- <div class="col-xl-6">
          <CreditScoreWidget
            :current_score="currentScore"
            :history="historyScore"
            :tickets="ticketsScore"
          />
        </div> -->
        <!-- End:: Credit score table  -->
        <!-- Begin:: educacion financiera table  -->
        <!-- <div class="col-xl-6">
          <FinanceEducationWidget :posts="fe_posts" />
        </div> -->
        <!-- End:: educacion financiera table  -->
        <!-- Begin:: beneficios  -->
        <!-- <div class="col-xl-6">
          <BenefitWidget :benefits="benefits" />
        </div> -->
        <!-- End:: beneficios table  -->
        <!-- Begin:: miplanseleccionado table  -->
        <div class="col-xl-6" id="plans">
          <PlanWidget :plan="plan" />
        </div>
        <!-- End:: miplanseleccionado table  -->
        <!-- Begin:: miscontratos table  -->
        <div class="col-xl-6">
          <DocumentWidget />
        </div>
        <!-- End:: miscontratos table  -->
        <!-- Begin:: misalertas table  -->
        <div class="col-xl-6">
          <AlertWidget />
        </div>
        <!-- End:: misalertas table  -->
      </div>
    </v-container>
    <WelcomeModal :useName="generalInformation.first_name" :activeModal="activeModal" @close="closeWelcome" />

    <PasswordModal v-if="changePassword" />
    <!-- End:: body page -->
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import TarjetaInfoWidget from '@/views/user/TarjetaInfoWidget.vue'
import AlertWidget from "@/components/alert/AlertWidget.vue";
import BenefitWidget from "@/components/user/plans/benefits/BenefitWidget.vue";
import CreditScoreWidget from "@/components/user/credit_score/CreditScoreWidget.vue";
import CreditCardWidget from "@/components/user/products/CreditCardWidget.vue";
import DesvareWidget from "@/components/user/products/DesvareWidget.vue"
import DocumentWidget from "@/components/document/DocumentWidget.vue";
import ElEmpleoWidget from "@/components/user/products/ElEmpleoWidget.vue";
import FinanceEducationWidget from "@/components/post/FinanceEducationWidget.vue";
import PlanWidget from "@/components/user/plans/PlanWidget.vue";
import PaymentWidget from "@/components/payment/PaymentWidget.vue";
import PasswordModal from "@/components/ui/PasswordModal.vue";
import ShortAlert from "@/components/ui/ShortAlert.vue";
import WelcomeModal from "@/components/ui/WelcomeModal.vue";

export default {
  name: "DashBoardHome",
  components: {
    AlertWidget,
    BenefitWidget,
    CreditCardWidget,
    CreditScoreWidget,
    DesvareWidget,
    DocumentWidget,
    ElEmpleoWidget,
    FinanceEducationWidget,
    PlanWidget,
    PasswordModal,
    PaymentWidget,
    ShortAlert,
    WelcomeModal,
    TarjetaInfoWidget
  },
  computed: {
    ...mapState({
      plans: state => state.plans.all,
      plan: state => state.plans.plan,
      benefits: state => state.plans.benefits,
      activeModal: state => state.users.welcomeModal,
      changePassword: state => state.users.changePassword,
      generalInformation: state => state.users.generalInformation,
      currentScore: state => state.creditScore.current,
      historyScore: state => state.creditScore.all,
      ticketsScore: state => state.creditScore.tickets,
      desvare: state => state.products.all,
      elempleo: state => state.products.elempleovip,
    }),
    ...mapGetters({
      alert: 'alerts/currentAlert',
      anyCreditCard: 'products/anyCreditCard',
      creditCard: 'products/activeCreditCard',
      acceptedCreditCard: 'products/acceptedOrDelivered',
      canceledWithArreasBalanceRP: 'products/canceledWithArreasBalanceRP',
      haveDelinquentAccounts: 'plans/haveDelinquentAccounts',
      hasPendingFees: 'fees/hasPendingFees',
      paymentAlerts: 'alerts/paymentAlerts',
      fe_posts: 'financeEducation/allPost',
      selectedPlan: 'users/selectedPlan',
      debtFeeAmount: 'fees/debtFeeAmount',
    })
  },
  methods: {
    closeWelcome() {
      $("#m_modal_1").modal('hide');
      this.$store.commit('users/closeModal');
    },
    changePlan(plan) {
      this.$store.dispatch("users/changePlan", plan);
    },
  }
  ,
  created() {
    this.$store.dispatch("payments/getAllPayments");
    this.$store.dispatch("products/getQntProducts");
    this.$store.dispatch("fees/getAllFees");
    this.$store.dispatch("plans/list");
    this.$store.dispatch("alerts/getAll");
    this.$store.dispatch("documents/getLegalDocument");
    this.$store.dispatch("financeEducation/getPublicPost");
    this.$store.dispatch("creditScore/getAll");
  },
  mounted() {
    if (this.activeModal) {
      $("#m_modal_1").modal('show');
    }
    if (localStorage.getItem('ch_ps')) {
      $("#m_modal_4").modal('show');
    }
  },
  watch: {
    selectedPlan(newValue, oldValue) {
      if (newValue.id && newValue.id != oldValue.id) {
        this.$store.dispatch("plans/get", newValue.id);
      }
    }
  },
};
</script>

<style lang="scss">
@import "@/styles/style.scss";
@import "vue-select/src/scss/vue-select.scss";

.plan .m-portlet .m-portlet__head .m-portlet__head-caption .m-portlet__head-title .m-portlet__head-text {
  font-size: 1.1rem;
}

@media (min-width: 1025px) {
  .qnt-alert {
    top: 80px;
  }
}

@media (max-width: 1024px) {
  .qnt-alert {
    top: 60px !important;
  }
}

.dropdown li a {
  padding: 10px 20px;
  display: flex;
  width: 100%;
  align-items: center;
}

.banner {
  min-height: 200px;
}
</style>