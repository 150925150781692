<template>
  <div class="m-portlet m-portlet--mobile border-3 border-primary border-top">
    <div class="m-portlet__head">
      <div class="m-portlet__head-caption">
        <div class="m-portlet__head-title">
          <router-link
            to="/plan"
            class="nav-link"
          >
            <h3 class="m-portlet__head-text">Mi Plan Seleccionado</h3>
          </router-link>
        </div>
      </div>
    </div>
    <div class="m-portlet__body">
      <div class="row p-3">
        <div class="col-6 col-lg-6 text-center">
          <div class="row">
            <div class="col-12 underLinde p-0">
              <b class="font18 fonFamilyBarlow">Nombre del plan</b>
            </div>
            <div class="col-12 py-2">{{plan.plan}}</div>
          </div>
        </div>
        <div class="col-6 col-lg-6 text-center">
          <div class="row">
            <div class="col-12 underLinde p-0">
              <b class="font18 fonFamilyBarlow">Monto financiado</b>
            </div>
            <div class="col-12 py-2">{{plan.monto_total  | currency}}</div>
          </div>
        </div>
        <div class="col-12 garySeparator my-3">&nbsp;</div>
        <div class="col-4 col-lg-4 mt-3 text-center">
          <div class="row">
            <div class="col-12 underLinde">
              <b class="font18 fonFamilyBarlow">Valor de la cuota</b>
            </div>
            <div class="col-12 py-2">{{plan.cuota  | currency}}</div>
          </div>
        </div>
        <div class="col-4 col-lg-4 mt-3 text-center">
          <div class="row">
            <div class="col-12 underLinde">
              <b class="font18 fonFamilyBarlow">Plazo de pago</b>
            </div>
            <div class="col-12 py-2">{{plan.plazo}} meses</div>
          </div>
        </div>
        <div class="col-4 col-lg-4 mt-3 text-center">
          <div class="row">
            <div class="col-12">
              <b class="font18 fonFamilyBarlow">Tasa de interés</b>
            </div>
            <div class="col-12 py-2">{{plan.tasa_ea}} %</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserPlanWidget",
  props: {
    plan: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>