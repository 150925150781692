<template>
<!--begin::Modal-->
<div v-if="activeModal" class="modal fade" id="m_modal_1" tabindex="-1" role="dialog" aria-labelledby="welcomeModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title text-center w-100 qnt-lead" id="welcomeModal">Bienvenido(a) a <strong>Mi Qnt</strong></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('close')" >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <div class="container-fluid">
                    <div class="row text-center">
                        <div class="col-12">
                            <h5 class="pb-1 pb-md-3 pb-xl-4 qnt-lead">Felicitaciones Sr.(a) {{useName}}</h5>
                            
                            <img src="../../assets/img/logos/mi-qnt.png" class="w-50 pb-md-2 pb-xl-3">
                            <p class="qnt-lead">Le presentamos Mi Qnt, donde podrá:</p>
                            
                            <ul class="list-unstyled">
                                <li class="pb-1 pb-md-2 pb-xl-3">Verificar su acuerdo de pago</li>
                                <li class="pb-1 pb-md-2 pb-xl-3">Consultar su plan de pago</li>
                                <li class="pb-1 pb-md-2 pb-xl-3">Administrar su información con Qnt</li>
                                <li class="pb-1 pb-md-2 pb-xl-3">Realizar pagos a través de diferentes canales</li>
                                <li class="pb-1 pb-md-2 pb-xl-3">Ingrese a través de qnt.com.co</li>
                                <li class="pb-1 pb-md-2 pb-xl-3">Disfrute de su cuenta Mi Qnt</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--end::Modal-->


</template>

<script>

import { mapActions, mapState} from "vuex"
export default {
    name: "WelcomeModal",
    props:{
        qntModalContent:{
            type:String,
            default:""
        },
        qntModalTitle:{
            type:String,
            default:""
        },
        useName:{
            type:String,
            default:""
        },
        activeModal:{
            type:Boolean,
            default:false
        },
    },
}
</script>


<style scoped lang="scss">
    img{
        max-width: 170px;
        min-width: 60px;
    }
    ul{
        font-weight: 800;
    }

    #m_modal_1{

        z-index:2000;

        h4{
            font-size:1.5em;
        }
    }
</style>

