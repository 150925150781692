<template>
<div :class="'m-alert m-alert--icon m-alert--air alert alert-'+type+' alert-dismissible fade show'" role="alert">
    <div class="m-alert__icon">
        <i class="la la-warning"></i>
    </div>
    <div class="m-alert__text">
        {{content}}
    </div>
    <div class="m-alert__close">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        </button>
    </div>
</div>


</template>


<script>

import { mapActions, mapState} from "vuex"
export default {
    name: "ShortAlert",
    props:{
        type:{
            type:String,
            default:""
        },
        content:{
            type:String,
            default:""
        },
    },
}
</script>

