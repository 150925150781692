<template>
<!--Begin::Portlet-->
<div class="m-portlet m-portlet--full-height m-portlet--mobile porlet-blue-border porlet-products">
  <div class="m-portlet__head">   
    <div class="m-portlet__head-caption">
      <div class="m-portlet__head-title">
          <h3 class="m-portlet__head-text">
            Puntaje de crédito
          </h3>
      </div>
    </div>
    <div class="m-portlet__head-tools">
      <ul class="m-portlet__nav">
        <li class="m-portlet__nav-item">
          <router-link to="/datos-crediticios" class="m-portlet__nav-link btn btn-info m-btn m-btn--pill m-btn--air">
            Ver detalles
          </router-link>
        </li>
      </ul>
    </div>
  </div>
  <div class="m-portlet__body pt-2">
      <div class="m-widget14 px-0 pb-1 pt-1">
        <div class="row align-items-center">
            <div class="col-12">
              <div class="m-widget4__ext text-center">
                  <span class="m-widget4__icon">
                    <div class="text-center mt-2">
                        <span class="small-text">Rango actual</span>
                        <h3 class="mb-0">{{current_score}}</h3>
                    </div>
                  </span>
              </div>
            </div>
        </div>
        <div class="row  align-items-center">
            <div class="col-12">
                <DashBoardTimeSeries
                  :y="y_values"
                  :x="x_values"  
                  :xaxis="'Fecha'"
                  :yaxis="'Rango'"
                  :title="'Historico de puntaje'"
                  :tickets="tickets" />
            </div>
        </div>
      </div>
  </div>
</div>
<!--End::Portlet-->
</template>

<script>
import { mapState, mapGetters } from "vuex";
import DashBoardTimeSeries from "@/components/dashboard/TimeSeries.vue";

export default {
  name: "UserCreditScoreWidget",
  components:{
        DashBoardTimeSeries,
    },
  props:{
    current_score:{
            type:String,
            default: ""
            },
    history: {
            type: Array,
            default: () => ([])
        },
    tickets: {
            type: Object,
            default: () => ({})
        },
  },
  computed: {

    y_values: function() {
      return this.history.map((item) => {return item["value"]});
      },
    x_values: function() {
      return this.history.map((item) => {return item["date"]});
      },
  },
};
</script>
