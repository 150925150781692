<template>
<!--Begin::Portlet-->
<div class="m-portlet m-portlet--full-height m-portlet--mobile border-primary border-top">
  <div class="m-portlet__head">
    
    <div class="m-portlet__head-caption">
      <div class="m-portlet__head-title">
        <div class="nav-link">
          <h3 class="m-portlet__head-text">
            Beneficios
          </h3>
        </div>
      </div>
    </div>
  </div>
  <div class="m-portlet__body">
    <div class="m-widget4">
      <div class="m-widget4__item"
        v-for="benefit in benefits"
        >
        <div class="m-widget4__img">
          <img :src="benefit.src_img" :alt="benefit.title" />
        </div>
        <div class="m-widget4__info">
          <span class="m-widget4__text">
            {{benefit.content}}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<!--End::Portlet-->
</template>

<script>
  export default {
    name: "UserPlanBenefitWidget",
    props:{
      benefits:{
              type: Array,
              default: () => ([])
          },
    },
  };
</script>

<style scoped>
  .m-widget4__item{
    cursor: pointer;
  }

  img{
      width: 80px;
    }
</style>
